import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Alinea from './pages/projets/Alinea.js';
import Contact from './pages/Contact.js';

import LongLiveTheKing from './pages/projets/LongLiveTheKing.js';
import Photos from './pages/Photos.js';
import Projects from './pages/Projects.js';
import Freedom from './pages/projets/Freedom.js';
import Opco from './pages/projets/Opco.js';
import Credit from './pages/projets/Credit.js';
import Grama from './pages/projets/Grama.js';
import Lavieclaire from './pages/projets/Lavieclaire.js';
import Madlords from './pages/projets/Madlords.js';
import Paris from './pages/projets/Paris.js';
import Rove from './pages/projets/Rove.js';
import Seeyouagain from './pages/projets/Seeyouagain.js';
import Taqt from './pages/projets/Taqt.js';
import Cite from './pages/projets/Cite.js';
import Educastream from './pages/projets/Educastream.js';
import Abrupt from './pages/Abrupt.js';
import Mbmilano from './pages/projets/Mbmilano.js';
import Printemps from './pages/projets/Printemps.js';
import Digoin from './pages/projets/Digoin.js';
import Cappadoce from './pages/projets/Cappadoce.js';
import Souslegui from './pages/projets/Souslegui.js';
import EducastreamMotion from './pages/projets/motion/EducastreamMotion.js';
import Cpf from './pages/projets/motion/Cpf.js';
import VonGoc from './pages/projets/VonGoc.js';
import Emlyon from './pages/projets/motion/Emlyon.js';
import Eurex from './pages/projets/motion/Eurex.js';
import Federec from './pages/projets/motion/Federec.js';
import Feebat from './pages/projets/motion/Feebat.js';
import IFinance from './pages/projets/motion/IFinance.js';
import IFinanceCourtage from './pages/projets/motion/IFinanceCourtage.js';
import MaNouvelleAssurance from './pages/projets/motion/MaNouvelleAssurance.js';
import Profeel from './pages/projets/motion/Profeel.js';
import Sncf from './pages/projets/motion/Sncf.js';
import Renault from './pages/projets/Renault.js';


const App = () => {
  return (
    
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Projects />} />
        <Route path="/abrupt" element = {<Abrupt/>} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects/mbmilano" element={<Mbmilano />} />
        <Route path="/projects/longlivetheking" element={<LongLiveTheKing />} />
        <Route path="/projects/educastream" element={<Educastream />} />
        <Route path="/projects/alinea" element={<Alinea />} />
        <Route path="/projects/printemps" element={<Printemps />} />
        <Route path="/projects/digoin" element={<Digoin />} />
        <Route path="/projects/cappadoce" element={<Cappadoce />} />
        <Route path="/projects/souslegui" element={<Souslegui />} />
        <Route path="/projects/cite" element={<Cite />} />
        <Route path="/projects/freedom" element={<Freedom />} />
        <Route path="/projects/opco" element={<Opco />} />
        <Route path="/projects/credit" element={<Credit />} />
        <Route path="/projects/grama" element={<Grama />} />
        <Route path="/projects/lavieclaire" element={<Lavieclaire />} />
        <Route path="/projects/madlords" element={<Madlords />} />
        <Route path="/projects/paris1985" element={<Paris />} />
        <Route path="/projects/rove" element={<Rove />} />
        <Route path="/projects/seeyouagain" element={<Seeyouagain />} />
        <Route path="/projects/taqt" element={<Taqt />} />
        <Route path="/projects/vongoc" element={<VonGoc />} />
        <Route path="/projects/renault" element={<Renault />} />


        <Route path="/projects/motion/educastream" element={<EducastreamMotion />} />
        <Route path="/projects/motion/cpf" element={<Cpf />} />
        <Route path="/projects/motion/emlyon" element={<Emlyon />} />
        <Route path="/projects/motion/eurex" element={<Eurex />} />
        <Route path="/projects/motion/federec" element={<Federec />} />
        <Route path="/projects/motion/feebat" element={<Feebat />} />
        <Route path="/projects/motion/ifinance" element={<IFinance />} />
        <Route path="/projects/motion/ifinancecourtage" element={<IFinanceCourtage />} />
        <Route path="/projects/motion/manouvelleassurance" element={<MaNouvelleAssurance />} />
        <Route path="/projects/motion/profeel" element={<Profeel />} />
        <Route path="/projects/motion/sncf" element={<Sncf />} />



        <Route path="*" element ={<Projects/>}/>
        
      </Routes>
      </BrowserRouter>
   
  );
};

export default App;